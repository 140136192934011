<template>
    <div>
        <div v-if="type === 'service'" class="contact-form">
            <b-overlay :show="showLoader" class="mt-1">
                <b-row>
                    <b-col class="contact p-1">
                        <validation-observer ref="validate">
                            <b-form class="form mb-1">
                                <b-row>
                                    <b-col>
                                        <b-form-group label-for="name">
                                            <validation-provider #default="{ errors }" name="ime in priimek" rules="required">
                                                <b-form-input id="name" v-model="object.name" placeholder="IME IN PRIIMEK" :disabled="true"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                                                <b-form-input id="email" v-model="object.email_from" placeholder="E-NASLOV" :disabled="true"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-form-group>
                                    <validation-provider #default="{ errors }" name="sporočilo" rules="required">
                                        <b-form-textarea id="text" v-model="object.message" rows="8" max-rows="8" placeholder="VAŠE SPOROČILO"/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <div class="d-flex justify-content-between align-items-center">
                                    <validation-provider #default="{ errors }" name="pogoji" rules="required">
                                        <b-form-checkbox v-model="checkbox">
                                            Strinjam se s <a href="/pogoji-poslovanja" target="_blank">pogoji</a> uporabe eSoseska.
                                        </b-form-checkbox>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <b-button pill class="button-custom pr-2" @click="validationForm">POŠLJI</b-button>
                                </div>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>
            </b-overlay>
        </div>
        <div v-else class="contact-form">
            <div class="d-flex justify-content-between align-items-center pb-1" style="border-bottom: 2px solid grey; font-size: 20px !important; font-weight: 700 !important;">
                <div>
                    Kontaktirajte za ogled
                </div>
                <div>
                    <b-button pill class="button-custom" v-if="contactData && contactData.telephone_number">
                        <!-- Telefonska mora biti nujno shranjena v obliki +386 xx xxx xxx -->
                        <a class="call-number" :href="'tel:' + contactData.telephone_number">
                            <div class="d-flex justify-content-between align-items-center">
                                Pokliči
                                <b-img class="ml-2" height="15px" :src="require('@/assets/images/icons/Phone.svg')"/>
                            </div>
                        </a>
                    </b-button>
                </div>
            </div>

            <b-overlay :show="showLoader" class="mt-1">
                <b-row>
                    <b-col v-if="contactData" cols="12">
                        <div style="font-size: 38px !important; font-weight: 700 !important;">
                            {{contactData.name}} <span v-if="contactData.surname">{{contactData.surname}}</span>
                        </div>
                    </b-col>
                    <b-col class="contact p-1">
                        <validation-observer ref="validate">
                            <b-form class="form mb-1">

                                <b-row>
                                    <b-col>
                                        <b-form-group>
                                            <validation-provider #default="{ errors }" name="ime in priimek" rules="required">
                                                <b-form-input id="name" v-model="object.name" placeholder="IME IN PRIIMEK"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                        <!--                                        <b-form-group>
                                            <validation-provider #default="{ errors }" name="datum" rules="required">
                                                <b-form-datepicker id="date" v-model="object.date" placeholder="DATUM"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>-->
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                                                <b-form-input id="email" v-model="object.email_from" placeholder="E-NASLOV"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                        <!--                                        <b-form-group>
                                            <validation-provider #default="{ errors }" name="ura" rules="required">
                                                <b-form-timepicker id="ura" v-model="object.time" locale="sl" placeholder="URA"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>-->
                                    </b-col>
                                </b-row>

                                <b-form-group>
                                    <validation-provider #default="{ errors }" name="sporočilo" rules="required">
                                        <b-form-textarea id="text" v-model="object.message" rows="8" max-rows="8" placeholder="VAŠE SPOROČILO"/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <div class="d-flex justify-content-between align-items-center">
                                    <validation-provider #default="{ errors }" name="pogoji" rules="required">
                                        <b-form-checkbox v-model="checkbox">
                                            Strinjam se s <a href="/pogoji-poslovanja" target="_blank">pogoji</a> uporabe eSoseska.
                                        </b-form-checkbox>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <b-button pill class="button-custom pr-2" @click="validationForm">POŠLJI</b-button>
                                </div>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>
            </b-overlay>
        </div>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    //import {BRow, BCol, BButton, BFormGroup, BFormTextarea, BForm, BFormInput, BOverlay, BFormCheckbox, BImg, BFormTimepicker, BFormDatepicker} from 'bootstrap-vue'
    import {BRow, BCol, BButton, BFormGroup, BFormTextarea, BForm, BFormInput, BOverlay, BFormCheckbox, BImg} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormTextarea,
            BForm,
            BFormInput,
            BOverlay,
            BFormCheckbox,
            //BFormTimepicker,
            //BFormDatepicker,
            BImg
        },
        props: {
            contactData: {
                type: Object,
                required: false
            },
            type: {
                type: String
            }
        },
        data() {
            return {
                checkbox: true,
                showLoader: false,
                object: {
                    name: '',
                    email_from: '',
                    email_to: '',
                    telephone_number: '',
                    message: '',
                    subject: ''
                },
                required,
                email
            }
        },
        methods: {
            validationForm() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        this.sendEmail()
                    }
                })
            },
            async sendEmail() {
                this.showLoader = true
                try {
                    this.object.email_to = this.contactData.email

                    if (this.type === 'service') {
                        this.object.subject = `Povpraševanje za storitev: ${this.object.name}`
                    } else {
                        //this.object.subject = `Povpraševanje za nepremičnino: ${this.object.name}, ${this.dayjs(this.object.date).format('DD.MM.YYYY')}, ${this.object.time.substring(0, 5)}`
                        this.object.subject = `Povpraševanje za nepremičnino: ${this.object.name}`
                    }

                    await this.$http.post('/api/user/v1/send_mail', this.object)
                    this.$printSuccess('Email je bil uspešno poslan')
                } catch (error) {
                    this.$printError(`Pri pošiljanju emaila je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            }
        },
        created() {
            if (this.$store.state.user.userData !== null) {
                this.object.name = `${this.$store.state.user.userData.name} ${this.$store.state.user.userData.surname}`
                this.object.email_from = this.$store.state.user.userData.email
                this.object.telephone_number = this.$store.state.user.userData.telephone_number
            }
        }
    }
</script>

<style scoped>
.form input, .form textarea{
  background-color: #E7E6E6;
  color: black !important;
}
.form-control {
  border-radius: 0;
  border: 1px solid #E7E6E6;
}
.form input::placeholder, .form textarea::placeholder {
  color: black;
}
.button:hover input { color:#fff }
input:focus, textarea:focus {
  background-color: transparent !important;
  border-color: white !important;
}
.button{
  color: #009FD4;
  background-color: white;
  font-weight: bold;
}
.call-number{
  color: white !important;
}
</style>

<style>
  .contact-form .form-control{
      color: black !important;
  }
  /* ,.contact-form .b-form-btn-label-control .btn, .contact-form .b-form-btn-label-control .b-form-time-control, .contact-form .form-control  */
  .contact-form .b-form-btn-label-control{
      background: #E7E6E6 !important;
  }

  .custom-checkbox .custom-control-label:before{
      /* background: red; */
      border-radius: 50%;
  }

  .contact-form .button-custom{
      max-width: 150px;
      width: 150px;
      text-align: left;
      background: #72A5D8 !important;
      font-size: 17px !important;
      /* font-weight: 600 !important; */
      height: 38px;
  }
</style>