var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'service')?_c('div',{staticClass:"contact-form"},[_c('b-overlay',{staticClass:"mt-1",attrs:{"show":_vm.showLoader}},[_c('b-row',[_c('b-col',{staticClass:"contact p-1"},[_c('validation-observer',{ref:"validate"},[_c('b-form',{staticClass:"form mb-1"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"ime in priimek","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"IME IN PRIIMEK","disabled":true},model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1537505201)})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"e-naslov","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"E-NASLOV","disabled":true},model:{value:(_vm.object.email_from),callback:function ($$v) {_vm.$set(_vm.object, "email_from", $$v)},expression:"object.email_from"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4149910014)})],1)],1)],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"sporočilo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"text","rows":"8","max-rows":"8","placeholder":"VAŠE SPOROČILO"},model:{value:(_vm.object.message),callback:function ($$v) {_vm.$set(_vm.object, "message", $$v)},expression:"object.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2886502352)})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{attrs:{"name":"pogoji","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}},[_vm._v(" Strinjam se s "),_c('a',{attrs:{"href":"/pogoji-poslovanja","target":"_blank"}},[_vm._v("pogoji")]),_vm._v(" uporabe eSoseska. ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1270779734)}),_c('b-button',{staticClass:"button-custom pr-2",attrs:{"pill":""},on:{"click":_vm.validationForm}},[_vm._v("POŠLJI")])],1)],1)],1)],1)],1)],1)],1):_c('div',{staticClass:"contact-form"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center pb-1",staticStyle:{"border-bottom":"2px solid grey","font-size":"20px !important","font-weight":"700 !important"}},[_c('div',[_vm._v(" Kontaktirajte za ogled ")]),_c('div',[(_vm.contactData && _vm.contactData.telephone_number)?_c('b-button',{staticClass:"button-custom",attrs:{"pill":""}},[_c('a',{staticClass:"call-number",attrs:{"href":'tel:' + _vm.contactData.telephone_number}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(" Pokliči "),_c('b-img',{staticClass:"ml-2",attrs:{"height":"15px","src":require('@/assets/images/icons/Phone.svg')}})],1)])]):_vm._e()],1)]),_c('b-overlay',{staticClass:"mt-1",attrs:{"show":_vm.showLoader}},[_c('b-row',[(_vm.contactData)?_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"font-size":"38px !important","font-weight":"700 !important"}},[_vm._v(" "+_vm._s(_vm.contactData.name)+" "),(_vm.contactData.surname)?_c('span',[_vm._v(_vm._s(_vm.contactData.surname))]):_vm._e()])]):_vm._e(),_c('b-col',{staticClass:"contact p-1"},[_c('validation-observer',{ref:"validate"},[_c('b-form',{staticClass:"form mb-1"},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"ime in priimek","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"IME IN PRIIMEK"},model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"e-naslov","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"E-NASLOV"},model:{value:(_vm.object.email_from),callback:function ($$v) {_vm.$set(_vm.object, "email_from", $$v)},expression:"object.email_from"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"sporočilo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"text","rows":"8","max-rows":"8","placeholder":"VAŠE SPOROČILO"},model:{value:(_vm.object.message),callback:function ($$v) {_vm.$set(_vm.object, "message", $$v)},expression:"object.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{attrs:{"name":"pogoji","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}},[_vm._v(" Strinjam se s "),_c('a',{attrs:{"href":"/pogoji-poslovanja","target":"_blank"}},[_vm._v("pogoji")]),_vm._v(" uporabe eSoseska. ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-button',{staticClass:"button-custom pr-2",attrs:{"pill":""},on:{"click":_vm.validationForm}},[_vm._v("POŠLJI")])],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }